import { Filesystem, Directory } from '@capacitor/filesystem'
import { readAsBase64 } from '@/lib/camera'

export const writeFile = async (webPath: string): Promise<string | null> => {
  try {
    const base64Data = await readAsBase64(webPath)
    const filename = new Date().getTime() + '.jpeg'

    await Filesystem.writeFile({
      path: filename,
      data: base64Data,
      directory: Directory.Data
    })

    return filename
  } catch (e) {
    console.log(e)
    return null
  }
}

export const readFile = async (filename: string): Promise<string | null> => {
  try {
    const readFile = await Filesystem.readFile({
      path: filename,
      directory: Directory.Data
    })

    return `data:image/jpeg;base64,${readFile.data}`
  } catch (e) {
    console.log(e)
    return null
  }
}

export const deleteFile = async (filename: string): Promise<void> => {
  try {
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data
    })
  } catch (e) {
    console.log(e)
  }
}
