import moment from 'moment'

moment.locale('es')

export const roundToNearestMinute = (date = new Date()) => {
  const minutes = 1
  const ms = 1000 * 60 * minutes

  const newDate = new Date(Math.ceil(date.getTime() / ms) * ms)
  newDate.setSeconds(0)

  return newDate
}

export const useDateFormat = (date: string) => moment(date).format('DD/MM/YYYY')

export const useDateFormatMonthYear = (date: string) => moment(date).format('MM/YYYY')

export const useDateFormatApi = (date: string) => moment(date).format('YYYY-MM-DD')

export const useDateTimeFormat = (date: string) => moment(date).format('DD/MM/YYYY HH:mm [h.]')


export const useDateTimeWithSecondsFormat = (date: string) => moment(date).format('DD/MM/YYYY HH:mm:ss')

export const useTimeFormat = (date: string) => moment(date).format('HH:mm:ss')

export const useUnixDatetimeFormat = (date: string) => moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
