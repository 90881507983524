import {defineStore} from 'pinia'
import {v4 as uuidv4} from 'uuid'

import {initDB} from '@/db/sqlite'
import {fetchLogin, fetchLogout, fetchMe, fetchRecoverPassword, fetchRefreshToken, fetchUpdatePassword} from '@/api'
import {LoginDTO, RecoverPasswordDTO, Settings, UpdatePasswordDTO, User} from '@/interfaces'
import router from '@/router'
import {useSyncStore} from '@/store/sync'
import {encrypt} from '@/lib/crypto'

interface AuthState {
  token: string | null
  refreshToken: string | null
  pushToken: string | null
  deviceId: string | null
  deviceType: string | null
  settings: Settings | null
  rememberSession: boolean
  rememberCredentials: { email: string; password: string } | null
  user: User | null,
  version: string | null
}

const defaultState: AuthState = {
  token: null,
  refreshToken: null,
  pushToken: 'push-token',
  deviceId: uuidv4(),
  deviceType: null,
  rememberSession: false,
  rememberCredentials: null,
  settings: null,
  user: null,
  version: null
}

export const useAuthStore = defineStore({
  id: 'auth',
  persist: true,
  state: () => defaultState,
  actions: {
    setDeviceType(type: string) {
      this.deviceType = type
    },

    async login(dto: LoginDTO & { rememberSession: boolean }) {
      try {
        const syncStore = useSyncStore()

        const {password, rememberSession, push, email} = dto

        const {token, refreshToken, user, settings} = await fetchLogin({password, push, email})
        this.rememberSession = rememberSession
        this.token = token
        this.settings = settings
        this.refreshToken = refreshToken
        this.user = user

        this.settings.keepValuesFromPreviousLine = false

        if (this.rememberSession) {
          const encryptedPassword = encrypt(password)
          if (encryptedPassword) {
            this.rememberCredentials = {
              email,
              password: encryptedPassword
            }
          }
        }

        await initDB()
        await syncStore.checkLaboratory(user)
        await syncStore.performSync()
      } catch (e) {
      }
    },

    async forgotPassword(dto: RecoverPasswordDTO) {
      try {
        return await fetchRecoverPassword(dto)
      } catch (e) {
      }
    },

    async refresh() {
      if (!this.refreshToken || !this.pushToken) {
        return
      }

      try {
        const {token, refreshToken} = await fetchRefreshToken({
          refreshToken: this.refreshToken,
          push: this.pushToken
        })
        this.token = token
        this.refreshToken = refreshToken
      } catch (e) {
      }
    },

    async updatePassword(dto: UpdatePasswordDTO) {
      try {
        return await fetchUpdatePassword(dto)
      } catch (e) {
      }
    },

    async logout() {
      try {
        await fetchLogout()
      } catch (e) {
      }
    },

    async setSettings(settings: Settings) {
      this.settings = settings
    },
    async me() {
      try {
        this.user = await fetchMe()
      } catch (e) {
      }
    },

    async localLogout() {
      this.token = null
      this.refreshToken = null
      this.user = null
      await router.push({name: 'login'})
    },

     setVersion(version: string) {
      this.version = version
    },

  },
  getters: {
    isUserLoggedIn: (state) => !!state.token,
    appVersion: (state) => state.version
  }
})
