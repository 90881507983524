import { apiFetch } from '@/api'
import { RefundFilter } from '@/store/refund'
import { NewRefundPost, Pagination, PostRefundResponse, Refund, RefundChart, StateFilters } from '@/interfaces'

export const fetchGetAllRefunds = async (filters: StateFilters & RefundFilter): Promise<Pagination<Refund>> =>
  (await apiFetch('/refunds', { method: 'GET', params: filters })).data

export const fetchGetRefundById = async (id: number): Promise<Refund> =>
  (await apiFetch(`/refunds/${id}`, { method: 'GET' })).data

export const fetchGetRefundWebviewById = async (id: number): Promise<string> =>
  (await apiFetch(`/refunds/webview/${id}`, { method: 'GET' }))

export const fetchGetRefundWebviewDetailById = async (id: number): Promise<string> =>
  (await apiFetch(`/refunds/webview/${id}/detail`, { method: 'GET' }))


export const fetchPostRefund = async (payload: NewRefundPost): Promise<PostRefundResponse> =>
  (await apiFetch('/refunds', { method: 'POST', body: payload })).data

export const fetchPostRefundFiles = async (formData: FormData): Promise<Refund> => {
  return (
    await apiFetch('/refunds/files', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  ).data
}

export const fetchGetRefundsLast30Days = async (): Promise<RefundChart[]> =>
  (await apiFetch('/refunds/home/chart/last30Days', { method: 'GET' })).data
