import {defineStore} from 'pinia'

import {
  GenericStoreState,
  Line,
  NewRefund,
  NewRefundPost,
  PostRefundResponse,
  Refund,
  StateFilters,
  StatePagination
} from '@/interfaces'
import {
  fetchGetAllRefunds,
  fetchGetRefundById,
  fetchGetRefundsLast30Days,
  fetchPostRefund,
  fetchPostRefundFiles,
  fetchGetRefundWebviewById,
  fetchGetRefundWebviewDetailById,
} from '@/api'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE} from '@/constants'
import {
  deleteNewRefundLine,
  deleteRefund,
  getNewRefundLines,
  getPendingRefunds,
  insertNewRefundLine,
  updateNewRefundLine
} from '@/db/sqlite'
import {deleteFile, readFile} from '@/lib/filesystem'
import {useControlStore} from '@/store/control'
import {LOCAL} from '@/constants'
import {useAppDeviceInfo} from '@/composables'
import {readAsBlob} from '@/lib/camera'
import moment from 'moment/moment'

// extend state
interface RefundState {
  localItems: NewRefund[]
  originPage: string | null
  newRefund: NewRefund | null
  lineToEdit: Line | null
  currentRefundTypeTab: string
}

// extend filters
export interface RefundFilter {
  text?: string | null
  finished?: boolean | null
  startDate?: string | null
  endDate?: string | null
}

const defaultFilters: StateFilters & RefundFilter = {
  text: '',
  startDate: '',
  endDate: '',
  finished: null,
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE
}

const defaultPagination: StatePagination = {
  total: 0,
  maxPages: 0
}

const defaultState: GenericStoreState<Refund | NewRefund, Refund | NewRefund, RefundFilter> & RefundState = {
  items: [],
  localItems: [],
  item: null,
  filters: {...defaultFilters},
  pagination: {...defaultPagination},
  originPage: null,
  newRefund: null,
  lineToEdit: null,
  currentRefundTypeTab: LOCAL
}

export const useRefundStore = defineStore({
  id: 'refund',
  state: () => defaultState,
  actions: {
    setCurrentRefundTypeTab(tab: string) {
      this.currentRefundTypeTab = tab
    },

    setNewRefundAttachment1(filename: string | null, base64: string | null) {
      if (!this.newRefund) {
        return
      }

      this.newRefund.attachment1 = {
        filename,
        base64
      }
    },

    setNewRefundAttachment2(filename: string | null, base64: string | null) {
      if (!this.newRefund) {
        return
      }

      this.newRefund.attachment2 = {
        filename,
        base64
      }
    },

    setNewRefundAttachment3(filename: string | null, base64: string | null) {
      if (!this.newRefund) {
        return
      }

      this.newRefund.attachment3 = {
        filename,
        base64
      }
    },

    setNewRefundClientSignature(filename: string | null, base64: string | null) {
      if (!this.newRefund) {
        return
      }

      this.newRefund.clientSignature = {
        filename,
        base64
      }
    },
    setPackageQuantity(packageQuantity: number) {
      if (this.newRefund) {
        this.newRefund.packageQuantity = packageQuantity
      }
    },
    setVirtual(virtual: boolean) {
      if (this.newRefund) {
        this.newRefund.virtual = virtual
      }
    },

    setNewRefundUserSignature(filename: string | null, base64: string | null) {
      if (!this.newRefund) {
        return
      }

      this.newRefund.userSignature = {
        filename,
        base64
      }
    },

    addTagCode(tagCode: string) {
      if (!this.newRefund?.tagCodes.includes(tagCode)) {
        this.newRefund?.tagCodes.push(tagCode)
      } else {
        const controlStore = useControlStore()
        controlStore.$patch({error: 'El código escaneado es igual a uno de los códigos escaneados previamente'})
      }
    },

    removeTagCode(tagCode: string) {
      if (!this.newRefund || !this.newRefund.tagCodes) return
      this.newRefund.tagCodes = this.newRefund?.tagCodes.filter((b) => b !== tagCode)
    },

    setDefaultFilters() {
      Object.assign(this.filters, defaultFilters)
    },

    setDefaultPagination() {
      Object.assign(this.pagination, defaultPagination)
    },

    someFiltersAreSet() {
      return this.filters.text !== '' || this.filters.startDate !== '' || this.filters.endDate !== ''
    },

    async getRefundLines() {
      if (!this.newRefund) {
        return []
      }

      this.newRefund.lines = await getNewRefundLines(this.newRefund.id)
    },

    async addLine(line: Line) {
      try {
        if (!this.newRefund) {
          return
        }

        await insertNewRefundLine(this.newRefund.id, line)
        await this.getRefundLines()
      } catch (e) {
        console.log('Error adding line', e)
      }
    },

    async removeLine(id: number) {
      if (!this.newRefund) {
        return
      }

      await deleteNewRefundLine(id)
      await this.getRefundLines()
    },

    async editLine(lineToEdit: Line) {
      if (!this.newRefund) {
        return
      }

      await updateNewRefundLine(lineToEdit)
      await this.getRefundLines()
    },

    async cancelRefund() {
      if (!this.newRefund) {
        return
      }

      if (this.newRefund.lines) {
        for (let i = 0; i < this.newRefund.lines.length; i++) {
          const line = this.newRefund.lines[i]
          if (line.lotImage) {
            await deleteFile(line.lotImage)
          }
        }
      }

      await deleteRefund(this.newRefund.id)

      this.originPage = null
      this.newRefund = null
    },

    async getRefundsLast30Days() {
      try {
        return await fetchGetRefundsLast30Days()
      } catch (e) {
        return []
      }
    },

    async getItems(dropLoadedItems = false) {
      try {
        if (dropLoadedItems) {
          this.items = []
          this.setDefaultPagination()
        }

        const {items, total, maxPages} = await fetchGetAllRefunds(this.filters)
        this.items = [...this.items, ...items]
        this.pagination.total = total
        this.pagination.maxPages = maxPages
      } catch (e) {
      }
    },

    async getLocalItems() {
      try {
        const controlStore = useControlStore()
        controlStore.$patch({loading: true})
        this.localItems = await getPendingRefunds({...this.filters, ...{finished: true}})
        controlStore.$patch({loading: false})
      } catch (e) {
      }
    },

    async getLocalItemsWithLines() {
      try {
        const items = await getPendingRefunds({finished: true})
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          item.lines = await getNewRefundLines(item.id)
        }

        return items
      } catch (e) {
        return []
      }
    },

    async getItem(id: number) {
      try {
        this.item = await fetchGetRefundById(id)
      } catch (e) {
      }
    },

    async getWebview(id: number) {
      try {
        return await fetchGetRefundWebviewById(id)
      } catch (e) {
      }
    },
    async getWebviewDetail(id: number) {
      try {
        return await fetchGetRefundWebviewDetailById(id)
      } catch (e) {
      }
    },
    async postItem(newRefund: NewRefundPost) {
      try {
        return await fetchPostRefund(newRefund)
      } catch (e) {
      }
    },

    async postItemFiles(formData: FormData) {
      try {
        return await fetchPostRefundFiles(formData)
      } catch (e) {
      }
    },

    async postCompleteRefund(newRefund: NewRefund): Promise<PostRefundResponse | null> {
      // send response files
      const refundFilesPayload = await this.mapToRefundPostFilesPayload(newRefund)
      const responseFiles = await this.postItemFiles(refundFilesPayload)
      if (!responseFiles) {
        return null
      }

      // send response data
      const newResponsePayload = await this.mapToRefundPostPayload(newRefund)
      const responseRefund = await this.postItem(newResponsePayload)
      if (!responseRefund) {
        return null
      }

      // delete local refund
      await deleteRefund(newRefund.id)

      return responseRefund
    },

    async mapToRefundPostPayload(newRefund: NewRefund): Promise<NewRefundPost> {
      const {platform, version} = await useAppDeviceInfo()
      const dateTime = moment(newRefund.datetime).format('YYYY-MM-DD HH:mm:ss')
      return {
        datetime: dateTime,
        type: newRefund.type,
        clientCode: newRefund.clientCode,
        clientName: newRefund.clientName,
        number: newRefund.numdev,
        email: newRefund.email,
        wholesaler: newRefund.wholesaler,
        observation: newRefund.comment,
        platform,
        version,
        tagCode: newRefund.tagCodes,
        virtual: newRefund.virtual,
        authorisation: newRefund.authorization,
        packageQuantity: newRefund.packageQuantity,
        lines: newRefund.lines.map((line: Line) => ({
          ean: line.ean,
          cn: line.nc,
          description: line.description,
          lotNumber: line.lotNumber,
          expirationDate: line.expirationDate,
          units: line.units,
          price: line.price.toFixed(2),
          depreciation: line.depreciation,
          amount: line.amount,
          reason: line.reason,
          destination: line.destination,
          sorting: line.sorting,
          eanSubstitution: line.eanReplacement,
          desSubstitution: line.descriptionReplacement,
          unitSubstitution: Number(line.unitsReplacement)
        }))
      }
    },

    async mapToRefundPostFilesPayload(newRefund: NewRefund): Promise<FormData> {
      const formData = new FormData()
      formData.append('number', newRefund.numdev)

      for (let i = 0; i < newRefund.lines.length; i++) {
        const line = newRefund.lines[i]

        if (line.lotNumber) {
          formData.append('lotNumber[]', line.lotNumber)
        }

        if (line.lotImage) {
          const base64 = await readFile(line.lotImage)
          if (base64) {
            formData.append('lotNumberImage[]', await readAsBlob(base64))
          }
        }
      }

      if (newRefund.attachment1.base64) {
        formData.append('attachment1', await readAsBlob(newRefund.attachment1.base64))
      }

      if (newRefund.attachment2.base64) {
        formData.append('attachment2', await readAsBlob(newRefund.attachment2.base64))
      }

      if (newRefund.attachment3.base64) {
        formData.append('attachment3', await readAsBlob(newRefund.attachment3.base64))
      }

      if (newRefund.clientSignature.base64) {
        formData.append('clientSignature', await readAsBlob(newRefund.clientSignature.base64))
      }

      if (newRefund.userSignature.base64) {
        formData.append('userSignature', await readAsBlob(newRefund.userSignature.base64))
      }

      return formData
    }
  }
})
